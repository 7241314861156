.rad-page {
  width: 100rem;
  max-width: 100%;
}
.mobile .rad-page {
  max-width: 25.5rem;
}

.rad-session-creation-card {
  width: 100%;
  max-width: 700px;
}
.mobile .rad-session-creation-card {
  max-width: 22.2rem;
}

.rad-card {
  width: 100%;
  max-width: 100%;
}
.mobile .rad-card {
  max-width: 23rem;
}