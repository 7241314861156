// app global css in SCSS form
.rad-page {
  width: 100rem;
  max-width: 100%;

  .mobile & {
    max-width: 25.5rem;
  }
}

.rad-session-creation-card {
  width: 100%;
  max-width: 700px;

  .mobile & {
    max-width: 22.2rem;
  }
}


.rad-card {
  width: 100%;
  max-width: 100%;

  .mobile & {
    max-width: 23rem;
  }
}
